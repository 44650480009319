html {
  font-family: sans-serif;
  line-height: 1.15; 
}

body {
  line-height: 1.5; 
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,Liberation Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
  margin: 0;
}

*, :after, :before {
  box-sizing: border-box;
}

/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 0.5rem;
  margin-top: 0;
}

button,
fieldset,
input,
legend,
select,
textarea {
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

input,
select,
textarea {
  border: 1px solid #ced4da;
  background-color: white;
  border-radius: 0.25rem;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="search"],
select,
textarea {
  font: 1rem / 1.5 sans-serif;
  display: block;
  box-sizing: border-box;
  width: 100%;
  padding: .375rem .75rem;
}

input[type="text"]:focus ,
input[type="email"]:focus ,
input[type="password"]:focus ,
input[type="search"]:focus ,
select:focus ,
textarea:focus {
  background-color: #fff;
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
  color: #495057;
  outline: 0;
}

textarea {
  min-height: 10rem;
}

input::placeholder,
textarea::placeholder {
  opacity: 1; /* Firefox */
  color: hsl(260, 16%, 50%);
}

select {
  background: url("../public/assets/images/down-arrow.svg") no-repeat center right 0.75rem;
}

input[type="radio"],
input[type="checkbox"] {
  height: 1.5em;
  width: 1.5em;
  vertical-align: middle;
}

input[type="radio"] {
  border-radius: 50%;
}

input[type="radio"]:checked {
  background-image: radial-gradient(
    hsl(122, 39%, 50%) 40%,
    transparent calc(40% + 1px)
  );
}

input[type="checkbox"]:checked {
  background: url('../public/assets/images/check.svg') no-repeat center / 75% auto;
}